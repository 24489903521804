<template>
  <div>
    <div class="header">
      <v-container fluid :class="[variant]">
        <v-row no-gutters align="center">
          <v-col cols="12" sm="4">
            <router-link :to="{ name: 'Home' }" class="home-link">
              <LogoHeader width="120" height="76" />
            </router-link>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="header__content">
              <div class="header__content--title">
                {{ pageTitle }}
              </div>
              <div class="header__content--subtitle">
                {{ pageSubtitle }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <v-row no-gutters justify="end" align="center">
              <v-col cols="auto">
                <div class="user-info">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <div class="user-info__icon">
                        <IconPictoUser />
                      </div>
                    </v-col>
                    <v-col>
                      <div>{{ $t("header.welcome") }}:</div>
                      <div class="user-info__fullname">
                        {{ $store.state.authModule.userProfile.full_name }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <div class="menu" @click.stop="drawer = !drawer">
                  <span class="ml-2">{{ $t("header.menu") }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <AppNavigationDrawer
        :active="drawer"
        @drawerUpdate="(payload) => (drawer = payload)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    variant: {
      type: String,
      default: () => "sky",
    },
  },
  components: {
    LogoHeader: () => import("~cp/Logo/LogoHeader"),
    IconPictoUser: () => import("~cp/Icons/IconPictoUser"),
    AppNavigationDrawer: () =>
      import("~cp/AppNavigationDrawer/AppNavigationDrawer"),
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    pageTitle() {
      return this.$route.meta?.pageInfo?.title;
    },
    pageSubtitle() {
      return !this.$route.meta?.pageInfo?.subtitleGetterVuex ? this.$route.meta?.pageInfo?.subtitle : this.$store.getters[this.$route.meta?.pageInfo?.subtitleGetterVuex]
    },
  },
};
</script>

<style src="./AppHeader.scss" lang="scss" scoped></style>
